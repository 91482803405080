<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeShiftLightbox"></div>

        <form
            class="shift-lightbox"
            :class="{ active: isShowDetail }"
            v-show="isShow"
        >
            <button
                type="button"
                class="btn-close"
                @click="closeShiftLightbox"
            ></button>

            <div class="max-vh-90 overflow-auto">
                <div class="lightbox-header">
                    <section class="lightbox-sec">
                        <p class="lightbox-subject">選擇班別 :</p>
                        <div class="row">
                            <div class="col-6">
                                <BaseSelect
                                    v-if="leaveInfo"
                                    title="班別"
                                    rootClass="custom-select"
                                    name="arrange_id"
                                    iconColor="#A2A2A2"
                                    :options="leaveInfo.arrange_list"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput
                                    type="number"
                                    title="補班數"
                                    name="make_up"
                                    rootClass="custom-input"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput
                                    type="number"
                                    title="正常班"
                                    name="pingban"
                                    rootClass="custom-input"
                                />
                            </div>
                            <div class="col-6">
                                <BaseInput
                                    type="number"
                                    title="加班"
                                    name="overtime"
                                    rootClass="custom-input"
                                />
                            </div>
                        </div>
                    </section>

                    <section class="lightbox-sec">
                        <p class="lightbox-subject">選擇假別 :</p>
                        <div class="choice-wrapper" v-if="leaveInfo">
                            <BaseRadio
                                v-for="(type, key) in leaveInfo.leave_type"
                                :key="type"
                                :value="Number(key)"
                                :label="type"
                                name="leave_type"
                            />
                            <ErrorMessage name="leave_type" />
                        </div>
                    </section>
                </div>

                <div class="lightbox-body" v-show="showDetailType === 5">
                    <section class="lightbox-sec">
                        <p class="lightbox-subject lh-sm">如進行調假或挪假，系統不會自動重算補班數，需自行調整數值</p>
                        <div class="choice-wrapper" v-if="leaveInfo">
                            <BaseRadio
                                v-for="(type, key) in leaveInfo.leave_ask_type"
                                :key="type"
                                :value="Number(key)"
                                :label="type"
                                name="leave_ask_type"
                            />
                            <ErrorMessage name="leave_ask_type" />
                        </div>
                    </section>

                    <section class="lightbox-sec">
                        <p class="lightbox-subject">選擇挪假日期 :</p>
                        <Datepicker
                            :timePicker="false"
                            v-model="date"
                            :startTime="initDate"
                            :format="formatDate"
                            :enableTimePicker="false"
                            hideInputIcon
                            :allowedDates="allowedChangeDates"
                        />

                        <p class="lightbox-subject">事由 :</p>
                        <BaseTextarea
                            name="leave_reason"
                            textareaClass="custom-textarea"
                        />
                    </section>
                </div>

                <div class="lightbox-body" v-show="showDetailType === 8">
                    <section class="lightbox-sec" v-if="leaveInfo">
                        <p class="lightbox-subject">分店選擇 :</p>
                        <BaseSelect
                            rootClass="custom-select"
                            name="support_store_id"
                            iconColor="#A2A2A2"
                            :options="storeList"
                        />
                    </section>
                </div>

                <div class="lightbox-footer">
                    <button type="button" class="btn-solid" @click="handleCancel">
                        {{ showDetailType === 8 ? '取消支援' : '取消請假' }}
                    </button>
                    <button
                        type="button"
                        class="btn-solid"
                        @click="handleSave"
                        :disabled="isSubmitting"
                    >
                        儲存
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { useForm, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import moment from "moment";
import { basePost } from "@/js/services/baseService";
import SwalCustom from "@/hooks/common/useSwalCustom";

export default {
    name: "ShiftLightbox",
    components: {
        ErrorMessage,
    },
    setup() {
        const { state, getters, dispatch } = useStore();
        const leaveInfo = computed(() => state.schedule.leaveInfo);
        const isShow = computed(() => state.global.isShiftLightboxShow);
        const { closeShiftLightbox } = useHandleLightboxOpen();
        let isShowDetail = ref(false);
        let showDetailType = ref(5);
        const date = ref();
        const initDate = ref();
        // form驗證設定
        const validationSchema = yup.object().shape({
            arrange_id: yup.string().required("必填"),
            make_up: yup.string().required("必填"),
            pingban: yup.string().required("必填"),
            overtime: yup.string().required("必填"),
            leave_type: yup.string().required("必填"),
            leave_ask_type: yup.string().when("leave_type", {
                is: "5",
                then: yup.string().required("必填"),
            }),
            leave_reason: yup.string().when("leave_type", {
                is: "5",
                then: yup.string().required("必填"),
            }),
            support_store_id: yup.number().when("leave_type", {
                is: "8",
                then: yup.number().min(1, "必填"),
            }),
        });
        const { values, handleSubmit, isSubmitting, resetForm } = useForm({
            validateOnMount: false,
            validationSchema,
        });
        const customSwal = new SwalCustom({
            title: "錯誤!",
            text: "",
            icon: "error",
            confirmButtonText: "確定",
        });
        // 允許挪假的日期
        const allowedChangeDates = computed(
            () => state.schedule.allowedChangeDates
        );

        const handleSave = handleSubmit(async (values, actions) => {
            const {
                leave_type,
                leave_ask_type,
                leave_reason,
                arrange_id,
                pingban,
                overtime,
                make_up,
                support_store_id,
            } = values;

            try {
                const res = await basePost("/api_roster/leave_check", {
                    store_id: state.auth.store_id,
                    member_id: state.schedule.selectedMemberId,
                    year: getters["schedule/getYear"],
                    month: Number(getters["schedule/getMonth"]),
                    day: Number(state.schedule.selectedDate),
                    roster_days_id: state.schedule.selectedRosterDaysId,
                    date:
                        leave_type == 5 && date.value != ""
                            ? moment(date.value).format("YYYY-MM-DD")
                            : "",
                    leave_type,
                    leave_ask_type,
                    leave_reason,
                    arrange_id,
                    pingban,
                    overtime,
                    make_up,
                    support_store_id: leave_type == 8 ? support_store_id : null,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    closeShiftLightbox();

                    // 重新請求當前日期的假表清單
                    dispatch("schedule/getScheduleList", {
                        store_id: state.auth.store_id,
                        year: getters["schedule/getYear"],
                        month: Number(getters["schedule/getMonth"]),
                    });

                    customSwal.fire({
                        title: "成功!",
                        icon: "success",
                        text: "排假成功",
                    });
                } else {
                    customSwal.fire({
                        text: data.res_content,
                    });
                }
            } catch (err) {
                console.error(err);
                customSwal.fire({
                    text: err.message,
                });
            }
        });

        const currentDate = moment().format("YYYY-MM-DD");


        const isSupportType = computed(() => {
            return showDetailType.value === 8
        })

        const handleCancel = () => {
            const payload = {
                id: state.schedule.selectedRosterDaysId,
                store_id: state.auth.store_id,
                member_id: state.schedule.selectedMemberId,
                year: getters["schedule/getYear"],
                month: Number(getters["schedule/getMonth"]),
                day: Number(state.schedule.selectedDate),
            };
            const cancel = async () => {
                try {
                    const res = await basePost(
                        "/api_roster/del_leave_checked",
                        payload
                    );
                    const { status, data } = res;

                    if (status === 200 && data.res_code === 1) {
                        closeShiftLightbox();

                        // 重新請求當前日期的假表清單
                        dispatch("schedule/getScheduleList", {
                            store_id: state.auth.store_id,
                            year: getters["schedule/getYear"],
                            month: Number(getters["schedule/getMonth"]),
                        });

                        customSwal.fire({
                            title: "成功!",
                            icon: "success",
                            text: "取消排假成功",
                        });
                    } else {
                        customSwal.fire({
                            text: data.res_content,
                        });
                    }
                } catch (err) {
                    console.error(err);
                    customSwal.fire({
                        text: err.message,
                    });
                }
            };

            const cancelSupport = async () => {
                try {
                    const res = await basePost(
                        "/api_roster/cancel_support_checked",
                        {
                            store_id: state.auth.store_id,
                            member_id: state.schedule.selectedMemberId,
                            roster_days_id: state.schedule.selectedRosterDaysId
                        }
                    );
                    const { status, data } = res;

                    if (status === 200 && data.res_code === 1) {
                        closeShiftLightbox();

                        // 重新請求當前日期的假表清單
                        dispatch("schedule/getScheduleList", {
                            store_id: state.auth.store_id,
                            year: getters["schedule/getYear"],
                            month: Number(getters["schedule/getMonth"]),
                        });

                        customSwal.fire({
                            title: "成功!",
                            icon: "success",
                            text: "取消支援成功",
                        });
                    } else {
                        customSwal.fire({
                            text: data.res_content,
                        });
                    }
                } catch (err) {
                    console.error(err);
                    customSwal.fire({
                        text: err.message,
                    });
                }
            };

            const { year, month } = payload;
            // 判斷是否大於等於當天時間
            const isAfter = moment(
                `${year}-${month}-${state.schedule.selectedDate}`
            ).isSameOrAfter(currentDate);

            if (!isAfter) {
                customSwal.fire({
                    title: `只能取消${currentDate}以後的${isSupportType.value ? '支援' : '排假'}`,
                    icon: "warning",
                });
                return;
            }

            customSwal
                .fire({
                    title: `確定取消${isSupportType.value ? '支援' : '排假'}?`,
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "取消",
                })
                .then((result) => {
                    const isConfirmed = result.isConfirmed;

                    if (isConfirmed) {
                        if(isSupportType.value) {
                            cancelSupport();
                        }else {
                            cancel();
                        }
                    }
                });
        };

        const formatDate = (date) => {
            return moment(date).format("YYYY-MM-DD");
        };

        // 更新初始資料
        watch(leaveInfo, () => {
            const {
                arrange_id,
                make_up,
                pingban,
                overtime,
                leave_type,
                leave_ask_type,
                leave_reason,
                date: initDate,
                support_store_id,
            } = leaveInfo.value.answer_list;

            resetForm({
                values: {
                    arrange_id,
                    make_up: make_up || 0,
                    pingban: pingban || 0,
                    overtime: overtime || 0,
                    leave_type: support_store_id ? 8 : leave_type,
                    leave_ask_type: leave_ask_type || "",
                    leave_reason,
                    support_store_id: support_store_id || 0,
                },
            });

            date.value = initDate;
        });

        watch(
            () => values.leave_type,
            (type) => {
                isShowDetail.value = type == 5 || type == 8 ? true : false;
                showDetailType.value = type;
            }
        );

        const storeList = computed(() =>
            leaveInfo.value.store_list.filter(
                (item) => item.id != state.auth.store_id
            )
        );

        return {
            isShow,
            closeShiftLightbox,
            leaveInfo,
            handleSave,
            isSubmitting,
            isShowDetail,
            showDetailType,
            initDate,
            date,
            formatDate,
            handleCancel,
            allowedChangeDates,
            storeList,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.shift-lightbox {
    max-width: 395px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff;

    &.active {
        .lightbox-body {
            height: auto;
            opacity: 1;
            position: initial;
        }

        .lightbox-footer {
            padding-top: 31px;
        }
    }

    &:deep(.custom-input) {
        height: auto !important;
        flex-wrap: nowrap;
        border: 1px solid #a2a2a2;
        border-radius: 5px;
        box-shadow: none !important;

        .input-group-prepend {
            padding: 7px 13px 7px 9px;
            position: relative;

            &::after {
                content: "";
                @include size(1px, 21px);
                background-color: rgba(112, 112, 112, 0.5);
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .input-group-text {
            display: block;
            min-width: 48px;
            padding: 0;
            font-size: 16px;
            text-align: justify;
            text-align-last: justify;
            text-justify: inter-ideograph;
            -ms-text-justify: inter-ideograph; //IE9/
            -moz-text-align-last: justify; //Firefox/
            -webkit-text-align-last: justify; //Chrome/
            background: transparent;

            &::after {
                content: "";
                display: inline-block;
                width: 100%;
            }
        }
    }

    &:deep(.custom-select) {
        min-height: 38px;
        border: 1px solid #a2a2a2;
        border-radius: 5px;
        box-shadow: none;
        background: transparent;

        .subject-item {
            min-width: 70px;
            padding: 7px 13px 7px 9px;

            &::after {
                height: 21px;
                background: rgba(112, 112, 112, 0.5);
            }
        }

        .subject {
            display: block;
            text-align: justify;
            text-align-last: justify;
            text-justify: inter-ideograph;
            -ms-text-justify: inter-ideograph; //IE9/
            -moz-text-align-last: justify; //Firefox/
            -webkit-text-align-last: justify; //Chrome/

            &::after {
                content: "";
                display: inline-block;
                width: 100%;
                position: absolute;
            }
        }

        .result-item {
            padding-top: 7px;
            padding-bottom: 7px;
        }

        .arrow-icon {
            background: transparent;

            svg {
                width: 12px;
            }
        }
    }

    &:deep(.custom-input) {
        input {
            width: calc(100% - 70px);
            padding: 0 10px;
            border: none;
            font-weight: 600;
            text-align: center;
            color: rgba(164, 100, 26, 1);

            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    &:deep(.base-radio) {
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        font-weight: 600;
    }

    &:deep(.dp__main) {
        width: 100%;
        margin-bottom: 10px;

        .dp__pointer {
            padding: 7px 10px !important;
            border: 1px solid #a2a2a2 !important;
            border-radius: 5px !important;
            font-size: 16px;
            background: transparent;
            background-image: url("~@/assets/tgd/icon/triangle.svg");
            background-repeat: no-repeat;
            background-position: calc(100% - 8px) calc((100% / 2) + 2px);
            color: #986d46;
        }

        svg {
            display: none;
        }
    }
    .max-vh-90  {
        max-height: 90vh;
    }
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    position: relative;
    z-index: 1;

    .lightbox-sec {
        &:first-child {
            padding: 20px 48px 11px 27px;

            .col-6 {
                &:nth-child(odd) {
                    padding-right: 8px;
                }

                &:nth-child(even) {
                    padding-left: 8px;
                }
            }
        }

        &:last-child {
            padding: 20px 48px 11px 27px;
            border-top: 1px solid rgba(214, 214, 214, 1);
        }
    }
}

.choice-wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .base-checkbox {
        margin-bottom: 12px;
    }

    span[role="alert"] {
        position: absolute;
        left: 0;
        top: calc(100% - 6px);
        font-size: 12px;
        color: #ff0000;
    }
}

.lightbox-subject {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
}

.lightbox-body {
    position: absolute;
    top: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
    transition: height position 0.3s ease;

    .lightbox-sec {
        &:first-child {
            padding: 15px 60px 15px 50px;
            box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: rgba(214, 214, 214, 0.3);
        }

        &:last-child {
            padding: 15px 50px;
            background-color: rgba(240, 240, 240, 1);
        }
    }
}

.lightbox-footer {
    padding: 0px 33px 31px 33px;
    transition: all 0.3s ease;
}

.btn-solid {
    padding: 8.5px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 1);

    &:first-child {
        width: 39%;
        margin-right: 10px;
        background: rgba(162, 162, 162, 1);
    }

    &:last-child {
        width: calc(100% - 39% - 10px);
        background-color: rgba(74, 74, 74, 1);
    }
}
</style>
