<template>
    <div class="st-container" v-if="scheduleList.length" :style="'min-width: 1300px'">
        <!-- controller -->
        <div class="st-controller-container">
            <div class="st-controller-group">
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'year',
                            isIncrease: false,
                            val: year,
                        })
                    "
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="st-controller-subject">{{ year }}</span>
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'year',
                            isIncrease: true,
                            val: year,
                        })
                    "
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>

            <div class="st-controller-group">
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'month',
                            isIncrease: false,
                            val: month,
                        })
                    "
                >
                    <i class="icon icon-arrow_left"></i>
                </button>
                <span class="st-controller-subject">{{ month }}月</span>
                <button
                    type="button"
                    class="st-controller"
                    @click="
                        updateDate({
                            updateType: 'month',
                            isIncrease: true,
                            val: month,
                        })
                    "
                >
                    <i class="icon icon-arrow_right"></i>
                </button>
            </div>
        </div>

        <!-- schedule -->
        <div class="st-table">
            <div class="st-header">
                <div class="st-row">
                    <div class="st-subject-sec">
                        <div class="st-holiday" data-type="honor">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">榮譽假</span>
                        </div>
                        <div class="st-holiday" data-type="honor-half">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">半天榮譽假</span>
                        </div>
                        <div class="st-holiday" data-type="official">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">例假日</span>
                        </div>
                        <div class="st-holiday" data-type="off">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">休假日</span>
                        </div>
                        <div class="st-holiday" data-type="leave">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">請假</span>
                        </div>
                        <div class="st-holiday" data-type="support">
                            <span class="st-holiday-symbol"></span>
                            <span class="st-holiday-txt">支援</span>
                        </div>
                    </div>

                    <div class="st-day-sec">
                        <StDayCol
                            v-for="(date, idx) in days"
                            :key="new Date().getTime() + idx"
                            :date="`${year}-${month}-${date}`"
                            :colIdx="idx"
                            :width="100 / days"
                        />
                    </div>
                </div>
            </div>

            <div class="st-body">
                <StAromaSec
                    v-for="(schedule, index) in scheduleList"
                    :key="schedule.member_name + index"
                    :rowIdx="index"
                    :scheduleObj="schedule"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import StAromaSec from "@/components/schedule/StAromaSec";
import StDayCol from "@/components/schedule/StDayCol";

export default {
    name: "StContainer",
    components: {
        StAromaSec,
        StDayCol,
    },
    setup() {
        const { state, getters, commit, dispatch } = useStore();
        const year = computed(() => Number(getters["schedule/getYear"]));
        const month = computed(() => Number(getters["schedule/getMonth"]));
        const days = computed(() => getters["schedule/getDays"]);
        const scheduleList = computed(() => state.schedule.scheduleList);

        const getScheduleList = () => {
            dispatch("schedule/getScheduleList", {
                store_id: state.auth.store_id,
                year: year.value,
                month: month.value,
            });
        };

        const updateDate = (params) => {
            const { updateType, isIncrease, val } = params;
            let num = isIncrease ? val + 1 : val - 1;
            let updatedVal = "";

            switch (updateType) {
                case "year":
                    if (num <= 0) {
                        num = "0";
                    }

                    updatedVal = `${num}-${month.value}`;
                    break;
                case "month":
                    let updatedYear = year.value;

                    if (num <= 0) {
                        num = "12";
                        updatedYear = updatedYear - 1;
                    } else if (num > 12) {
                        num = "01";
                        updatedYear = updatedYear + 1;
                    }

                    updatedVal = `${updatedYear}-${num}`;
                    break;
            }

            commit("schedule/update", {
                key: "currentDate",
                value: updatedVal,
            });

            getScheduleList();
        };

        getScheduleList();

        return { year, month, days, scheduleList, updateDate };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.st-controller-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 600;

    .st-controller-group {
        display: flex;
        padding: 0 20px;
    }

    .st-controller {
        border: none;
        background-color: transparent;
    }

    .st-controller-subject {
        padding: 0 20px;
    }
}

.st-header {
    .st-row {
        display: flex;
    }

    .st-subject-sec {
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px;
        width: 320px;
        flex-shrink: 0;
    }

    .st-holiday {
        display: flex;
        align-items: center;
        margin-right: 30px;
        margin-bottom: 6px;

        &[data-type="honor"] .st-holiday-symbol {
            background-color: #b77f1a;
        }

        &[data-type="honor-half"] .st-holiday-symbol {
            background-color: #6d4545;
        }

        &[data-type="official"] {
            .st-holiday-symbol {
                background-color: #0079f2;
            }
            .st-holiday-txt {
                color: #0079f2;
            }
        }

        &[data-type="off"] .st-holiday-symbol {
            background-color: #05aa66;
        }

        &[data-type="leave"] .st-holiday-symbol {
            background-color: #ff416f;
        }
        &[data-type="support"] .st-holiday-symbol {
            background-color: #fff;
        }

        .st-holiday-symbol {
            @include size(18px);
            margin-right: 9px;
            border-radius: 50%;
            border: 1px solid #fff;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            box-sizing: border-box;
        }

        .st-holiday-txt {
            font-size: 16px;
            color: #a2a2a2;
        }
    }

    .st-day-sec {
        display: flex;
        align-items: flex-end;
        width: calc(100% - 320px);
    }
}
.st-body {
    overflow: overlay;
    max-height: calc(100vh - 500px);
    @media (max-width: 1200px) {
        max-height: calc(100vh - 420px); 
    }
    &::-webkit-scrollbar-track {
        background-color: #d6d6d6;
        border-radius: 2px;
    }
    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background: #707070;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 9px;
    }
}
</style>
